import { init, render, goTo } from "../lib/es6_global/src/EntrypointWithPreload.bs";

import "~/dist/pages.css";

// We store a copy of the fetch function in window.makerFetch
// This is to avoid the fetch function being overwritten by other scripts
// Very common for other trackers on the page to overwrite it.
window.makerFetch = window.makerFetch || window.fetch;

const scriptOrigin =
  (document.currentScript &&
    document.currentScript.src.replace(
      /(https?:.+)(\/smartnav-.+\.js)(\?id=(.*))?/g,
      "$1"
    )) ||
  "https://showside.maker.co";

if (localStorage) {
  localStorage.setItem("smartnav-script-origin", scriptOrigin);
}

const headID = document.getElementsByTagName("head")[0];
const createImgDomainsPreconnectLinks = () => {
  const linkShopifyPreconnect = document.createElement("link");
  linkShopifyPreconnect.rel = "preconnect";
  linkShopifyPreconnect.href = "https://cdn.shopify.com";

  const linkCloudinaryPreconnect = document.createElement("link");
  linkCloudinaryPreconnect.rel = "preconnect";
  linkCloudinaryPreconnect.href = "https://res.cloudinary.com";

  return [linkShopifyPreconnect, linkCloudinaryPreconnect];
};

const createMakerDomainsPreconnectLinks = () => {
  const smartnavPreconnect = document.createElement("link");
  smartnavPreconnect.rel = "preconnect";
  smartnavPreconnect.href = "https://api.maker.co";

  return [smartnavPreconnect];
};

const links = [
  ...createImgDomainsPreconnectLinks(),
  ...createMakerDomainsPreconnectLinks(),
];
headID.append(...links);

// Wait for window.maker_optimize that might be appended
setTimeout(init, 0);

window.addEventListener("DOMContentLoaded", () => init());

window.SmartNav = {
  init,
  render,
  goTo,
};
