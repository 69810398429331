// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as CriticalCSS$Showside from "./utils/CriticalCSS.bs.js";
import * as Webapi__Dom__Element from "../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as Configuration$Showside from "./types/Configuration.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
async function initSmartnav(index, element) {
  EmbedTypes$Showside.Attributes.autoAssignNamespaces(undefined);
  var attributes = EmbedTypes$Showside.Attributes.fromElement(element);
  var match = element.id;
  var embedType;
  switch (match) {
    case "maker-smartnav-launcher":
      embedType = /* Launcher */2;
      break;
    case "maker-smartnav-section":
      embedType = /* Section */3;
      break;
    default:
      embedType = /* Bottombar */0;
  }
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, undefined, attributes, embedType);
  if (configId !== undefined) {
    return Configuration$Showside.preloadConfig(undefined, undefined, configId);
  }
}
function querySelectorAll(selector) {
  return Belt_Array.keepMap(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll(selector)), Webapi__Dom__Element.ofNode), function (node) {
    return node;
  });
}
function getElements(param) {
  var elements = querySelectorAll("#maker-smartnav, #maker-smartnav-launcher, #maker-smartnav-section");
  var makerPagesModal = document.querySelector("#maker-modal");
  var shop = Shopify$Showside.getShop(undefined);
  var match = elements.length !== 0;
  if (match) {
    return elements;
  }
  if (shop === undefined) {
    return [];
  }
  if (!(makerPagesModal == null)) {
    return [];
  }
  var child = document.createElement("div");
  child.id = "maker-smartnav";
  Belt_Option.forEach(document.body, function (param) {
    param.appendChild(child);
  });
  return [child];
}
function init(param) {
  var elements = getElements(undefined);
  if (elements.length !== 0) {
    Belt_Array.mapWithIndex(elements, initSmartnav);
    CriticalCSS$Showside.insertCSS(undefined, undefined);
    import('./types/ConfigurationDecoder.bs');
    import('./Entrypoint.bs').then(entrypoint => {
      entrypoint.init();
      window.SmartNav = entrypoint;
    });
    import('./utils/Slider.bs');
    import('./apps/LauncherApp.bs');
    import('./apps/BottombarApp.bs');
    return import('./apps/SectionApp.bs');
  } else {
    console.warn("Could not initialize SmartNav: no element found");
    return;
  }
}
function goTo(quickView, quickNav, replaceState, preserveScroll, namespace) {
  import('./Entrypoint.bs').then(entrypoint => {
    entrypoint.goTo(quickView, quickNav, replaceState, preserveScroll, namespace);
  });
}
function render(configId, onOpenLink, onAddToCart, trackers, isLauncher, element, attributes) {
  import('./Entrypoint.bs').then(entrypoint => {
    entrypoint.render(configId, onOpenLink, onAddToCart, trackers, isLauncher, element, attributes);
  });
}
export { initSmartnav, querySelectorAll, getElements, init, goTo, render };
/* Shopify-Showside Not a pure module */